import axios from 'axios';

export interface NewsletterFormData {
  email: string;
}

export async function submitNewsletterWebhook(formData: NewsletterFormData) {
  const webhookUrl = `https://hooks.zapier.com/hooks/catch/12446590/3o7y8tt/?email=${formData.email}`;

  return axios.post(webhookUrl);
}

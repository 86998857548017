import ResponsiveImage from '../../responsive-image';
import './card.scss';

const Card = ({
  img,
  imgXL,
  title,
  description,
}: {
  img: string;
  imgXL: string;
  title: string;
  description: JSX.Element;
}) => {
  return (
    <div className="card">
      <ResponsiveImage sm={img} lg={imgXL} alt="brand" />
      <div>
        <h3>{title}</h3>
        {description}
      </div>
    </div>
  );
};

export default Card;

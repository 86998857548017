import { useEffect, useState } from 'react';
import { TeamDepartment as TD } from '../../useTeamMembers';
import { Button } from '../../../../components/button/button';
import Icon from '../../../../components/icon/icon';
import TeamMember from '../team-member/team-member';
import './team-department.scss';

const TeamDepartment = ({
  title,
  members,
  opened = false,
  setSectionsVisible,
}: {
  title: string;
  members: TD['members'];
  opened: boolean;
  setSectionsVisible: Function;
}) => {
  const [isOpen, setIsOpen] = useState(!opened);

  useEffect(() => {
    setIsOpen(opened);
  }, [opened]);

  return (
    <div className="team-department">
      <div className="team-department__heading">
        <h2>{title}</h2>
        <Button
          variant="text"
          icon={<Icon name={isOpen ? 'ChevronUp' : 'ChevronDown'} />}
          onClick={() => {
            setIsOpen(!isOpen);
            setSectionsVisible();
          }}
        />
      </div>
      {isOpen && (
        <div className="team-department__members">
          {members.map((member) => (
            <TeamMember
              key={member.name}
              name={member.name}
              role={member.role}
              imageUrl={member.url}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default TeamDepartment;

import { Link } from 'react-router-dom';
import { Pages } from '../../global/routes';
import { SocialIconsFooter } from '../social-icons/social-icons';

import './footer-bottom-row.scss';

export const FooterBottomRow = () => {
  const year = new Date().getFullYear();

  return (
    <div className="footer__bottom-row">
      <div className="footer__bottom-row__copyright">
        © {year} ReneVerse. All rights reserved.{' '}
        <Link to={Pages.PRIVACY_POLICY}>Privacy Policy</Link>
      </div>

      <div className="footer__bottom-row__social">
        Follow us
        <SocialIconsFooter />
      </div>
    </div>
  );
};

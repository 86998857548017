import axios from 'axios';
import { useEffect, useState } from 'react';
import { CDN_CHAIN_LOGOS, STATS_CDN, STATS_URL_PRD, STATS_URL_STG } from '../../global/constants';
import { ExternalLinkButton } from '../../components/button/button';
import Icon from '../../components/icon/icon';
import ReneLogo from '../../components/rene-logo';
import ResponsiveImage from '../../components/responsive-image';

import defaultImg from './images/avatar.webp';
import statsTitle from './images/stats-title.webp';
import statsTitleLg from './images/stats-title-xl.webp';

import './stats-page.scss';

interface Stats {
  users: number;
  games: number;
  orgs: number;
  assets: {
    mainnet: number;
    testnet: number;
  };
  transactions: {
    mainnet: number;
    testnet: number;
  };
  chains: {
    mainnet: number;
    testnet: number;
    symbol: string;
    name: string;
    mainnetPercentage: string;
    testnetPercentage: string;
  }[];
  mainnetTransactions: number;
  testnetTransactions: number;
  topOrgs: {
    name: string;
    imageUrl: string;
  }[];
  topGames: {
    name: string;
    imageUrl: string;
  }[];
}

interface Links {
  [key: string]: {
    testnet: string;
    mainnet: string;
  };
}

const TopStat = ({
  title,
  list,
}: {
  title: string;
  list: { name: string; imageUrl: string }[] | undefined;
}) => {
  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const target = e.target as HTMLImageElement;
    target.src = defaultImg;
  };

  return (
    <div className="stats-page__top_stats_stat">
      <h1>{title}</h1>
      {list?.length &&
        list.map((item) => (
          <div key={item.imageUrl}>
            <img src={item.imageUrl || defaultImg} alt="top stat" onError={handleImageError} />
            <p>{item.name}</p>
          </div>
        ))}
    </div>
  );
};

const StatsPage = () => {
  const [stats, setStats] = useState<Stats>();
  const [links, setLinks] = useState<Links>();

  useEffect(() => {
    let api = `${STATS_URL_STG}?detailed=true`;
    if (window.location.host === 'reneverse.io') {
      api = `${STATS_URL_PRD}?detailed=true`;
    }
    axios.get<Stats>(api).then((res) => {
      setStats(res.data);
    });

    axios.get<Links>(STATS_CDN).then((res) => {
      setLinks(res.data);
    });
  }, []);

  return (
    <div className="stats-page">
      <ReneLogo />
      <ResponsiveImage sm={statsTitle} lg={statsTitleLg} alt="title" />
      <div className="stats-page__stats">
        <div>
          <h4>{stats?.users}</h4>
          <p>Users & Wallets</p>
        </div>
        <div>
          <h4>{stats?.orgs}</h4>
          <p>Organizations</p>
        </div>
        <div>
          <h4>{stats?.games}</h4>
          <p>Games</p>
        </div>
      </div>
      <div className="stats-page__top_stats">
        <TopStat title="Top Games" list={stats?.topGames} />
        <div className="stats-page__top_stats_transactions">
          <h1>Assets and Transactions</h1>
          <div className="stats-page__top_stats_transactions_assets">
            <div>
              <div />
              <p>Mainnet</p>
              <p>Testnet</p>
            </div>
            <div>
              <div>Assets</div>
              <p>{stats?.assets?.mainnet}</p>
              <p>{stats?.assets?.testnet}</p>
            </div>
            <div>
              <div>Transactions</div>
              <p>{stats?.transactions?.mainnet}</p>
              <p>{stats?.transactions?.testnet}</p>
            </div>
          </div>
          <h2>Chain</h2>
          <div className="stats-page__top_stats_transactions_chain">
            {links &&
              stats?.chains.map((stat) => (
                <div key={stat.symbol}>
                  <div>
                    <img src={`${CDN_CHAIN_LOGOS}/${stat.symbol?.toLowerCase()}.svg`} alt="chain" />
                    <p>{stat.name}</p>
                  </div>
                  <ExternalLinkButton variant="link" href={links[stat.symbol]?.mainnet}>
                    {stat?.mainnetPercentage}
                    <Icon name="Link" />
                  </ExternalLinkButton>
                  <ExternalLinkButton variant="link" href={links[stat.symbol]?.testnet}>
                    {stat?.testnetPercentage}
                    <Icon name="Link" />
                  </ExternalLinkButton>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsPage;

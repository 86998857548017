// Social media
export const DISCORD_URL = 'https://discord.com/invite/reneverse';
export const DISCORD_INVITE_URL = 'https://discord.com/invite/Gw7E6UEXcv';
export const TWITTER_URL = 'https://twitter.com/ReneVerse_io';
export const REDDIT_URL = 'https://www.reddit.com/r/Reneverse/';
export const MAIL_ADDRESS = 'mailTo:marketing@reneverse.io';
export const TELEGRAM_URL = 'https://t.me/reneverse';
export const MEDIUM_URL = 'https://medium.com/@ReneVerse';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/reneverse-io/';
export const HACKEN_URL = 'https://hacken.io/audits/reneverse/';

export const RENE_PORTAL_URL = 'https://app.reneverse.io/';
export const RENE_PORTAL_REGISTER = 'https://app.reneverse.io/register';
export const RENE_DOCS_URL = 'https://docs.reneverse.io/';
export const RENE_TOUR_URL = 'https://docs.reneverse.io/reneverse-tour/';
export const RENE_GETTING_STARTED_URL = 'https://docs.reneverse.io/getting-started/';

export const API_URL = 'https://services.dev.reneverse.io/api';
export const UNSTOP_URL =
  'https://unstop.com/competitions/the-reneverse-borderless-gaming-hackathon-reneverse-670185';
export const PRODUCT_HUNT_URL =
  'https://www.producthunt.com/products/reneverse-sdk?utm_source=badge-top-post-badge&utm_medium=badge#reneverse-unity-sdk';

export const HOME_URL = 'https://cdn.reneverse.io/home-page/home-page.json';
export const STATS_URL_DEV = 'https://services.dev.reneverse.io/api/stats';
export const STATS_URL_STG = 'https://services.stg.reneverse.io/api/stats';
export const STATS_URL_PRD = 'https://services.reneverse.io/api/stats';
export const FAQ_URL = 'https:///cdn.reneverse.io/faq/faq.json';
export const WHITEPAPER_URL = 'https://cdn.reneverse.io/docs/reneverse-whitepaper.pdf';
export const TOKENOMICS_URL = 'https://cdn.reneverse.io/docs/tokenomic-whitepaper.pdf';
export const STATS_CDN = 'https://cdn.reneverse.io/stats-page/stats-page.json';
export const DEVELOPER_DOCS_URL =
  'https://docs.reneverse.io/getting-started/registering-as-a-player-or-developer';

export const EMAIL_VALIDATION_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const CDN_CHAIN_LOGOS = 'https://cdn.reneverse.io/chain';

export const AIRDROP_URL = 'https://app.galxe.com/quest/Reneverse';

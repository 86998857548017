export const htmlEscape = (html: string) => {
  return html.replaceAll('<', '&lt;').replaceAll('>', '&gt;');
};

export const htmlDecode = (content: string) => {
  let e = document.createElement('div');
  e.innerHTML = htmlEscape(content);
  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
};

export const isObjEmpty = (obj: any) => Object.keys(obj).length === 0;

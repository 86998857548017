import { FC, SourceHTMLAttributes } from 'react';

interface ResponsiveImageProps {
  xxl?: SourceHTMLAttributes<HTMLSourceElement>['srcSet'];
  xl?: SourceHTMLAttributes<HTMLSourceElement>['srcSet'];
  lg?: SourceHTMLAttributes<HTMLSourceElement>['srcSet'];
  md?: SourceHTMLAttributes<HTMLSourceElement>['srcSet'];
  sm: SourceHTMLAttributes<HTMLSourceElement>['srcSet'];
  alt: string;
}

const ResponsiveImage: FC<ResponsiveImageProps> = ({ xxl, xl, lg, md, sm, alt }) => (
  <picture>
    {xxl ? <source media="(min-width: 1440px)" srcSet={xxl} /> : null}
    {xl ? <source media="(min-width: 1280px)" srcSet={xl} /> : null}
    {lg ? <source media="(min-width: 992px)" srcSet={lg} /> : null}
    {md ? <source media="(min-width: 768px)" srcSet={md} /> : null}
    {sm ? <source media="(min-width: 576px)" srcSet={sm} /> : null}
    <img src={sm} alt={alt}></img>
  </picture>
);

export default ResponsiveImage;

import { FC } from 'react';

import './team-member.scss';

interface TeamMemberProps {
  name: string;
  role: string;
  imageUrl: string;
  active?: boolean;
}

const TeamMember: FC<TeamMemberProps> = ({ name, role, imageUrl, active }) => {
  return (
    <div className={`team-member ${active ? 'active' : ''}`}>
      <img src={imageUrl} alt={name} />
      <h3>{name}</h3>
      <p>{role}</p>
    </div>
  );
};

export default TeamMember;

import React, { useLayoutEffect, useRef, useState } from 'react';

interface IProps {
  id?: string;
  animateClassName?: string;
  threshold?: number;
  children: JSX.Element[] | JSX.Element;
}

const Animate: React.FC<IProps> = ({ id, children, animateClassName, threshold = 1 }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [animate, setAnimate] = useState(false);
  useLayoutEffect(() => {
    let observer: IntersectionObserver;

    const handleIntersect = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting && !animate) {
          setAnimate(true);
        }
      });
    };

    let options = {
      root: null,
      rootMargin: '0px',
      threshold,
    };

    observer = new IntersectionObserver(handleIntersect, options);

    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => observer.disconnect();
  });
  return (
    <div id={id} ref={ref} className={animate ? `${animateClassName}` : 'initial-state'}>
      {children}
    </div>
  );
};

export default Animate;

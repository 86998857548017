import './game-demo-page.scss';

const GameDemoPage = () => {
  return (
    <div className="game-demo-page">
      <div className="game-demo-page__title">
        <h1>
          IN-GAME <span>BORDERLESS ADS</span> SHOWCASE
        </h1>
        <h2>Explore live Unity game integrated with ReneVerse Immersive Advertising</h2>
      </div>
      <iframe src="https://cdn.reneverse.io/game/index.html" allowFullScreen title="game" />
    </div>
  );
};

export default GameDemoPage;

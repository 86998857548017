import { NavLink } from 'react-router-dom';
import { Pages } from '../../global/routes';
import { ExternalLinkButton } from '../button/button';
import { RENE_DOCS_URL } from '../../global/constants';
import { FooterBottomRow } from './footer-bottom-row';
import Icon from '../icon/icon';
import ReneLogo from '../rene-logo';
import FooterMenu from '../footer-menu/footer-menu';
import Newsletter from '../newsletter/newsletter';
import './footer.scss';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__section">
          <NavLink to={Pages.HOME} className="logo-container">
            <ReneLogo />
          </NavLink>
          <div className="footer__section footer__section--links">
            <FooterMenu className="footer__menu" />
          </div>
        </div>

        <div className="footer__section footer__docs">
          <h3>Documentation</h3>
          <div>
            <ExternalLinkButton variant="link" href={RENE_DOCS_URL}>
              Developer Documentation
            </ExternalLinkButton>
            {/* <ExternalLinkButton variant="link" href={TOKENOMICS_URL}>
              Token Whitepaper
            </ExternalLinkButton> */}
          </div>
        </div>

        <div className="footer__section footer__info">
          <h3>Get In Touch</h3>
          <div>
            <Icon name="Pin" />
            <p>Singapore</p>
          </div>
          <div>
            <Icon name="Mail" />
            <a href="mailto:contact@reneverse.io">contact@reneverse.io</a>
          </div>
        </div>

        <div className="footer__section footer__newsletter">
          <Newsletter />
        </div>
        <div className="footer__separator" />
      </div>
      <FooterBottomRow />
    </footer>
  );
};

export default Footer;

import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Pages } from '../../global/routes';
import Icon from '../icon/icon';

interface MenuProps {
  className?: string;
  closeMenu?(): void;
  location?: any;
  children?: JSX.Element;
}

const Menu: FC<MenuProps> = ({ className, location, children }) => {
  return (
    <nav className={className}>
      <div>
        <NavLink to={Pages.ADVERTISERS}>Advertisers</NavLink>
        <NavLink to={Pages.PUBLISHERS}>Publishers</NavLink>
        <NavLink to={Pages.GAME_DEMO}>Demo</NavLink>
      </div>
      <div className="team-link">
        <span
          className={
            location.pathname === '/docs-faq' || location.pathname === '/about' ? 'active' : ''
          }
        >
          About <Icon name="ChevronDown" />
        </span>
        <div className={`${className} team-dropdown`}>
          <NavLink to={Pages.ABOUT}>About</NavLink>
          <NavLink to={Pages.DOCS_FAQ}>Docs & FAQ</NavLink>
        </div>
        {children}
      </div>
    </nav>
  );
};

export default Menu;

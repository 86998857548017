import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Pages } from '../../global/routes';

interface MenuProps {
  className?: string;
  closeMenu?(): void;
  children?: JSX.Element;
}

const FooterMenu: FC<MenuProps> = ({ className, children }) => {
  return (
    <nav className={className}>
      <div>
        <NavLink to={Pages.HOME}>Home</NavLink>
        <NavLink to={Pages.ADVERTISERS}>Advertisers</NavLink>
        <NavLink to={Pages.PUBLISHERS}>Publishers</NavLink>
        <NavLink to={Pages.ABOUT}>About</NavLink>
      </div>
    </nav>
  );
};

export default FooterMenu;

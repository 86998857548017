import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import reneLogo from '../assets/images/logo/reneverse.svg';

interface HeadMetaProps {
  title: string;
  metaImage?: string;
  description?: string;
  page?: string;
}

const HeadMeta: FC<HeadMetaProps> = (props) => {
  // TODO: dont use window directly
  const url = window.location.toString();
  const origin = window.location.origin;

  const title = `${props.title}`;
  const page = `${props.page}`;
  const description = props.description ?? 'Infuse your game with blockchain';
  const metaImage = props.metaImage ? new URL(props.metaImage, origin).toString() : reneLogo;
  const siteName = 'ReneVerse';

  const keywords: any = {
    home: 'gaming sdk, game dev, casual gamers, unity sdk',
    about: 'gaming revenue, borderless gaming, reneverse, game revenue',
    product: 'reneverse sdk, unity android sdk, game api',
    rene: 'rene token, interoperability token, game token',
    docs: 'reneverse faq, reneverse help, reneverse doc',
  };

  return (
    <Helmet>
      <title>{title} | ReneVerse</title>

      <meta name="description" content={description} />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={metaImage} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={siteName} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@reneverse_" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={metaImage} />

      {keywords[page] && <meta name="keywords" content={keywords[page]} />}
    </Helmet>
  );
};

export default HeadMeta;

import { FC, useState, useEffect } from 'react';
import { useTeamMembers } from './useTeamMembers';
import { Button } from '../../components/button/button';
import Animate from '../../components/animate/animate';
import HeadMeta from '../../components/head-meta';
import TeamDepartment from './components/team-department/team-department';
import ResponsiveImage from '../../components/responsive-image';

import hero from './images/about.webp';
import heroXL from './images/about-xl.webp';

import './about-page.scss';
import './animations.scss';

const AboutPage: FC = () => {
  const { teamDepartments } = useTeamMembers();
  const [sectionsVisible, setSectionsVisible] = useState<boolean[]>([]);

  useEffect(() => {
    setSectionsVisible(Array(teamDepartments.length).fill(false));
  }, [teamDepartments.length]);

  const updateSectionVisibility = (index: number) => {
    const sections = [...sectionsVisible];
    sections[index] = !sections[index];
    setSectionsVisible([...sections]);
  };

  return (
    <div className="about-page">
      <HeadMeta title="About us" description="Team members of ReneVerse" page="about" />
      <section className="about-page__landing">
        <div className="about-page__landing_hero">
          <ResponsiveImage sm={hero} xl={heroXL} alt="reneverse" />
        </div>
        <div className="about-page__landing_content">
          <h1>Blending the Borders between</h1>
          <span>Games and Brands</span>
        </div>
      </section>
      <Animate animateClassName="animate-testimonial" threshold={0.5}>
        <section className="about-page__testimonial">
          <h2>
            “Our mission is to <span>empower players, developers, and brands</span> to bring their
            assets with them on their own unique gaming journeys.”
          </h2>
          <p>Anik Dang, Founder</p>
        </section>
      </Animate>
      <Animate animateClassName="animate-team" threshold={0.5}>
        <section className="about-page__team">
          <div>
            <h2>Our team</h2>
            <h3>
              We’re building the future of gaming, which we believe is <b>borderless</b>.<br />
              <br /> Our team knows that the best games are developed through painstaking attention
              to detail, a collaborative spirit, and a willingness to break the mold.
              <br />
              <br /> That’s why we built ReneVerse. A place where players, developers, and brands
              are all important stakeholders in the future of gaming.
            </h3>
          </div>
          <div>
            {teamDepartments?.map((department, i) => (
              <TeamDepartment
                key={department.title}
                title={department.title}
                members={department.members}
                opened={sectionsVisible[i]}
                setSectionsVisible={() => updateSectionVisibility(i)}
              />
            ))}
            {!sectionsVisible.every((s: boolean) => s === true) && (
              <Button
                type="button"
                onClick={() => setSectionsVisible(Array(teamDepartments.length).fill(true))}
                variant="text"
              >
                SHOW ALL
              </Button>
            )}
          </div>
        </section>
      </Animate>
    </div>
  );
};

export default AboutPage;

const tutorialLink =
  'https://www.youtube.com/watch?v=OrIn7mqsTuU&list=PL7otCRt-btSsqdOroCF2ZL1eYh92JZflF';

export const data = {
  games: [
    {
      title: 'What is the integration/onboarding procedure like?',
      description: (
        <p>
          Initial registration and generating your API credentials can be done within an hour in the
          ReneVerse Portal. From there, mapping ad surfaces in your game is completed in Unity, and
          this process can take more time depending on how many surfaces you intend to map. Download
          the SDK, add your API credentials to make SDK calls, and begin mapping Borderless Ad
          Surfaces, where each will receive an ID based on which SDK call will serve the ad creative
          of the intended brand campaign.
          <br />
          <br />
          <i>NOTE</i>: ReneVerse also offers JavaScript SDKs, so any Web/JS project can integrate.
          <br />
          <br />
          For an in-depth breakdown of the integration process for games to publish Borderless Ads,
          dive into our Developer Docs or check out our step-by-step video instructions.
        </p>
      ),
    },
    {
      title: 'How can I participate as an ad publisher in the Borderless Ads Pilot?',
      description: (
        <p>
          The Borderless Ads Pilot is akin to an alpha phase – it will be a limited run, in which a
          growing collection of ReneVerse-connected games and brands collaborate to release multiple
          2-3 month-long Borderless Ad campaigns. Designed to collect valuable data, including but
          not limited to brand campaign impression averages, ad revenue and ROI, and player
          interactivity, the Pilot will serve as a proof-of-concept and a testing phase. For
          developers interested in supplementing their revenue by publishing non-intrusive ads,
          participation in the Borderless Ads Pilot phase will offer preferential ad revenue rates
          during the Pilot and for 3 months after the main release of Borderless Ads.
        </p>
      ),
    },
    {
      title:
        'What option do your ad surfaces have when it comes to wrapping around objects and how is this facilitated?',
      description: (
        <p>
          ReneVerse’s Borderless Ad surfaces are textures whose final visual appearance depends on
          the currently running ad content. However, if you map the texture to the object you want
          to wrap correctly, and you upload ad content to that texture within the ReneVerse Portal,
          you can achieve any desired visual result on any 3D object.
        </p>
      ),
    },
    {
      title: 'How could we incorporate our Blender assets into your SDK?',
      description: (
        <p>
          3D asset models are not incorporated within the SDK. Rather, they are imported directly
          into Unity and mapped to ReneVerse asset templates and their ad surfaces.
        </p>
      ),
    },
    {
      title:
        'How can I block certain ad types from being visible in my game as it does not fit in with the environment?',
      description: (
        <p>
          There is no blocking of ad types, but there will be the option to tag games for specific
          targeting.
        </p>
      ),
    },
    {
      title:
        'Can we turn off specific ad surfaces? What will that look like in the game when turned off?',
      description: (
        <p>
          Developers can perform direct SDK calls to dynamically update ad surfaces with content, so
          they have full control over any changes in their appearance and when to enact those
          changes.
        </p>
      ),
    },
  ],
  brands: [
    {
      title:
        'What kinds of content can be used in Borderless Ads? Are there specific formats or size requirements that are needed?',
      description: (
        <div>
          <p>
            Size requirements for ad content are dictated by the Intrinsic In-Game Advertising
            Measurement Guidelines 2.0 published by the Interactive Advertising Bureau (IAB). Aside
            from these guidelines, we recommend aligning the size of your content with the size of
            the ad surfaces integrated by the game developer, but there is no strict requirement to
            abide by conventional aspect ratios.
            <br />
            <br />
            ReneVerse is offering ads at heavily discounted prices both during the Pilot and for 3
            months after the official release of Borderless Ads. During the Pilot, we will be
            gathering ad performance data to better formulate pricing for the future of Borderless
            Ads. If you are interested in running a campaign for the Pilot and wish to learn about
            the different prices for our different ad types, please contact us.
          </p>
        </div>
      ),
    },
    {
      title: 'Can we run ads that, when clicked or interacted with, produce promotions or rewards?',
      description: (
        <p>
          The mechanism behind interactions with clickable Borderless Ads will be initially based on
          sending an email to the player who interacts with the ad. The email body is open-ended and
          allows for brands to reach out to those who interact with their ads and encourage a
          desired action without interrupting their play sessions. The content of the email itself
          will be defined by the advertising brand and can contain any content, links, or CTAs that
          further their campaign goals.
        </p>
      ),
    },
    {
      title:
        'Can a branded asset also be a working game asset, with real usability? For example, could we make a playable arcade game machine as an asset and add that to a ReneVerse-partnered game?',
      description: (
        <p>
          Yes, it can. Currently, a Branded Asset is merely any in-game 3D asset (static or
          interactive) that is mapped to a ReneVerse asset template and Borderless Ad surfaces. The
          3D asset in question can demonstrate any in-game behavior or execute any triggered action
          that is defined by the <b>game developer</b>.
          <br />
          <br />
          Therefore, a playable arcade game as a Branded Asset is <b>possible</b>. We will emphasize
          that such functionality – and those that are more intricate – would necessitate either the
          involvement of an asset developer to make the Branded Asset on behalf of your brand or the
          expertise of a third-party.
          <br />
          <br />
          Disclaimer: At present, ReneVerse is not able assist in the production of Branded Assets
          with unique in-game behaviors.
        </p>
      ),
    },
    {
      title: 'How can I participate as an advertiser in the Borderless Ads Pilot?',
      description: (
        <p>
          The Borderless Ads Pilot is akin to an alpha phase – it will be a limited run, in which a
          growing collection of ReneVerse-connected games and brands collaborate to release multiple
          2-3 month-long Borderless Ad campaigns. Designed to collect valuable data, including but
          not limited to brand campaign impression averages, ad revenue and ROI, and player
          interactivity, the Pilot will serve as a proof-of-concept and a testing phase.
          <br />
          <br />
          For brands interested in advertising to hyper-focused gamer audiences and achieving
          unparalleled access to them while they play, ad packages in the Borderless Ads Pilot phase
          are up to 60% off CPC/CPM, both during the Pilot and for 3 months after the main release
          of Borderless Ads. To sign up, click here!
        </p>
      ),
    },
    {
      title:
        'Can the advertising target link be placed on H5 or does it have to be an app download address?',
      description: (
        <p>
          Since Borderless Ads interactions have the option to define an email template with any
          desired content, any link can be added, allowing brands to define conversion flows to any
          online destination, including specific app or product landing pages.
        </p>
      ),
    },
    {
      title: 'What kinds of ads are suitable for vintage games?',
      description: (
        <p>
          If the vintage game’s engine is Unity or Web/JS, then Borderless Ads will work. Regarding
          their suitability for your specific game, it would depend on the genre of your game.
        </p>
      ),
    },
  ],
  other: [
    {
      title: 'Is there a demo video of borderless ads?',
      description: (
        <p>
          You can find an in-depth integration tutorial{' '}
          <a href={tutorialLink} target="_blank" rel="noreferrer">
            here
          </a>
          .
        </p>
      ),
    },
    {
      title: 'Is there any supporting documentation for Borderless Ads?',
      description: (
        <p>
          Yes, take a look at our Developer Docs to get a thorough walkthrough of Borderless Ads
        </p>
      ),
    },
    {
      title: 'What Security measures are taken into consideration for SDK integration?',
      description: (
        <p>
          Developers who wish to integrate the ReneVerse SDK have full control over how to integrate
          ad surfaces and define impressions or interactions tied to them. As for dynamically served
          ad content and ad objects, like Branded Assets, these are stored in ReneVerse’s database
          and specifically retrieved from the ad content/asset owner’s organization or
          ReneVerse-connected game. These are all protected with API keys and robust registration
          procedures.
        </p>
      ),
    },
  ],
};

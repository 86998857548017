import { FC } from 'react';
import {
  DISCORD_URL,
  LINKEDIN_URL,
  MAIL_ADDRESS,
  TELEGRAM_URL,
  TWITTER_URL,
} from '../../global/constants';
import { Icons } from '../icon/icon-types';

import './social-icons.scss';

interface SocialIconProps {
  iconName: Icons;
  url: string;
}

export const SocialIcon: FC<SocialIconProps> = ({ iconName, url }) => (
  <a
    className="social-icons__icon"
    href={url}
    rel="noopener noreferrer"
    target="_blank"
    aria-label="Follow us on social networks"
  >
    <i className={`icon icon-${iconName}`} />
  </a>
);

export const SocialIconsHeader: FC = () => (
  <div className="social-icons">
    <SocialIcon iconName={Icons.SocialX} url={TWITTER_URL} />
    <SocialIcon iconName={Icons.SocialLinkedin} url={LINKEDIN_URL} />
    <SocialIcon iconName={Icons.SocialDiscord} url={DISCORD_URL} />
    <SocialIcon iconName={Icons.Mail} url={MAIL_ADDRESS} />
  </div>
);

export const SocialIconsFooter: FC = () => (
  <div className="social-icons">
    <SocialIcon iconName={Icons.SocialX} url={TWITTER_URL} />
    <SocialIcon iconName={Icons.SocialTelegram} url={TELEGRAM_URL} />
    <SocialIcon iconName={Icons.SocialLinkedin} url={LINKEDIN_URL} />
    <SocialIcon iconName={Icons.SocialDiscord} url={DISCORD_URL} />
    <SocialIcon iconName={Icons.Mail} url={MAIL_ADDRESS} />
  </div>
);

import { AnchorHTMLAttributes, ButtonHTMLAttributes, FC, ReactElement, ReactNode } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import Icon from '../icon/icon';
import Spinner from '../spinner/spinner';

import './button.scss';

type ButtonSize = 'lg' | 'md' | 'sm' | 'xs';
type ButtonVariant = 'primary' | 'secondary' | 'line' | 'accent' | 'text' | 'link';
type IconPosition = 'left' | 'right';
interface BaseButtonProps {
  icon?: ReactElement<typeof Icon> | null;
  iconPosition?: IconPosition;
  size?: ButtonSize;
  variant?: ButtonVariant;
  className?: string;
  children?: ReactNode;
  loading?: boolean;
}

type LinkButtonProps = Omit<NavLinkProps, 'className'> & BaseButtonProps;
type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & BaseButtonProps;
type ExternalLinkButtonProps = AnchorHTMLAttributes<HTMLAnchorElement> & BaseButtonProps;

const defaultProps: BaseButtonProps = {
  iconPosition: 'left',
  size: 'md',
  variant: 'primary',
};

function buttonFactory<T extends BaseButtonProps>(
  component: FC<Omit<T, keyof BaseButtonProps>>,
): FC<T> {
  return (props: T) => {
    const { children, icon, iconPosition, className, size, variant, loading, ...rest } = {
      ...defaultProps,
      ...props,
    };
    const buttonSize = `button--${size || 'md'}`;
    const buttonVariant = `button--${variant || 'primary'}`;
    const buttonIconOnly = icon && !children ? 'button--icon-only' : '';
    const buttonIconRight = icon && iconPosition === 'right' ? 'button--icon-right' : '';
    const classNames = className ? className : '';
    const buttonClassName = `button ${buttonSize} ${buttonVariant} ${buttonIconOnly} ${buttonIconRight} ${classNames}`;

    return component({
      ...rest,
      className: buttonClassName,
      children: loading ? (
        <Spinner />
      ) : (
        <>
          {children}
          {icon}
        </>
      ),
    });
  };
}

export const LinkButton = buttonFactory<LinkButtonProps>((props) => <NavLink {...props} />);
export const ExternalLinkButton = buttonFactory<ExternalLinkButtonProps>(
  ({ children, ...props }: ExternalLinkButtonProps) => (
    <a rel="noreferrer noopener" {...props} target="_blank">
      {children}
    </a>
  ),
);
export const Button = buttonFactory<ButtonProps>((props) => <button {...props} />);

export type IconsId =
  | "arrow-down"
  | "arrow-forward"
  | "check-circle"
  | "chevron-down"
  | "chevron-up"
  | "click"
  | "close"
  | "hamburger"
  | "image"
  | "link"
  | "loading"
  | "mail"
  | "pin"
  | "plus"
  | "pyramid"
  | "social-discord"
  | "social-linkedin"
  | "social-medium"
  | "social-reddit"
  | "social-telegram"
  | "social-x"
  | "video";

export type IconsKey =
  | "ArrowDown"
  | "ArrowForward"
  | "CheckCircle"
  | "ChevronDown"
  | "ChevronUp"
  | "Click"
  | "Close"
  | "Hamburger"
  | "Image"
  | "Link"
  | "Loading"
  | "Mail"
  | "Pin"
  | "Plus"
  | "Pyramid"
  | "SocialDiscord"
  | "SocialLinkedin"
  | "SocialMedium"
  | "SocialReddit"
  | "SocialTelegram"
  | "SocialX"
  | "Video";

export enum Icons {
  ArrowDown = "arrow-down",
  ArrowForward = "arrow-forward",
  CheckCircle = "check-circle",
  ChevronDown = "chevron-down",
  ChevronUp = "chevron-up",
  Click = "click",
  Close = "close",
  Hamburger = "hamburger",
  Image = "image",
  Link = "link",
  Loading = "loading",
  Mail = "mail",
  Pin = "pin",
  Plus = "plus",
  Pyramid = "pyramid",
  SocialDiscord = "social-discord",
  SocialLinkedin = "social-linkedin",
  SocialMedium = "social-medium",
  SocialReddit = "social-reddit",
  SocialTelegram = "social-telegram",
  SocialX = "social-x",
  Video = "video",
}

export const ICONS_CODEPOINTS: { [key in Icons]: string } = {
  [Icons.ArrowDown]: "61697",
  [Icons.ArrowForward]: "61698",
  [Icons.CheckCircle]: "61699",
  [Icons.ChevronDown]: "61700",
  [Icons.ChevronUp]: "61701",
  [Icons.Click]: "61702",
  [Icons.Close]: "61703",
  [Icons.Hamburger]: "61704",
  [Icons.Image]: "61705",
  [Icons.Link]: "61706",
  [Icons.Loading]: "61707",
  [Icons.Mail]: "61708",
  [Icons.Pin]: "61709",
  [Icons.Plus]: "61710",
  [Icons.Pyramid]: "61711",
  [Icons.SocialDiscord]: "61712",
  [Icons.SocialLinkedin]: "61713",
  [Icons.SocialMedium]: "61714",
  [Icons.SocialReddit]: "61715",
  [Icons.SocialTelegram]: "61716",
  [Icons.SocialX]: "61717",
  [Icons.Video]: "61718",
};

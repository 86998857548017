import React, { useState } from 'react';
import { Button } from '../button/button';
import './slider.scss';

interface IProps {
  slides: { [key: string]: number | string | string[] | { [key: string]: string } }[];
  SlideComponent: React.ElementType<any>;
}

const Slider: React.FC<IProps> = ({ slides, SlideComponent }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const totalSlides = slides.length;

  const goToPrevious = () => {
    setCurrentSlide((prev) => (prev - 1 + totalSlides) % totalSlides);
  };

  const goToNext = () => {
    setCurrentSlide((prev) => (prev + 1) % totalSlides);
  };

  return (
    <div className="slider">
      <div className="slider__content" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
        {slides.map((slide, i) => (
          <SlideComponent key={i} {...slide} />
        ))}
      </div>
      <div className="slider__buttons">
        <Button variant="text" onClick={goToPrevious}>
          <p>{`<`}</p>
        </Button>
        <Button variant="text" onClick={goToNext}>
          <p>{`>`}</p>
        </Button>
      </div>
    </div>
  );
};

export default Slider;

import { useState } from 'react';
import { Button, LinkButton } from '../../../components/button/button';

import './feature-ads.scss';

import slide1 from './images/image-1.webp';
import slide2 from './images/image-2.webp';
import slide3 from './images/image-3.webp';
import slide4 from './images/image-4.webp';
import { Pages } from '../../../global/routes';

export const slides = [
  {
    img: slide1,
    title: 'Dynamic Ads On Any Surface or Object',
    p1: 'ReneVerse’s Borderless Ads offers dynamic image and video ad content across the surface of any in-game asset. And for the first time ever, brands can easily add their own in-game ad objects known as Branded Assets.',
    button: (
      <LinkButton to={Pages.ADVERTISERS} variant="line">
        View Ads
      </LinkButton>
    ),
  },
  {
    img: slide2,
    title: 'Scale Your Ads Like Never Before',
    p1: 'With the ReneVerse SDK, game developers can easily define ad surface inventory in their games, and once that’s done, they will be able to publish campaigns from multiple brands simultaneously.',
    p2: 'ith the ReneVerse Portal, brands can create and manage their ad campaigns, and one ad campaign can deliver ads simultaneously across multiple games within the ReneVerse ecosystem.',
    button: (
      <LinkButton to={Pages.DOCS_FAQ} variant="line">
        Learn more
      </LinkButton>
    ),
  },
  {
    img: slide3,
    title: 'Enhance Your Game with Ads - Not Distract',
    p1: 'With ReneVerse, game developers don’t have to sacrifice their players’ game satisfaction to monetize with in-game ads. Borderless Ads offers games a variety of non-intrusive ad types that not only augment your revenue with ad dollars but also protect, if not enhance, the immersive experience of your game.',
    button: (
      <LinkButton to={Pages.PUBLISHERS} variant="line">
        Become a Publisher
      </LinkButton>
    ),
  },
  {
    img: slide4,
    title: 'Reach Better Gamer Audiences with Better Ads',
    p1: 'Rather than wasting time integrating the same campaign into multiple games, brands can choose to prep 1 ad campaign once and distribute across multiple games simultaneously. Similarly, instead of adding each ad campaign separately, games can define their ad surfaces a single time and onboard multiple brands simultaneously.',
    button: (
      <LinkButton to={Pages.ADVERTISERS} variant="line">
        Advertise with ReneVerse
      </LinkButton>
    ),
  },
];

const FeatureAds = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = slides.length;
  // const slideInterval = 3000;

  const goToPrevious = () => {
    setCurrentSlide((prev) => (prev - 1 + totalSlides) % totalSlides);
  };

  const goToNext = () => {
    setCurrentSlide((prev) => (prev + 1) % totalSlides);
  };

  // useEffect(() => {
  //   let interval: any = null;

  //   if (window.innerWidth > 992) {
  //     interval = setInterval(() => {
  //       setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
  //     }, slideInterval);
  //   }
  //   return () => {
  //     if (interval) clearInterval(interval);
  //   };
  // }, [totalSlides, slideInterval]);

  return (
    <div className="feature-ads">
      <div
        className="feature-ads__slider"
        style={window.innerWidth < 992 ? { transform: `translateX(-${currentSlide * 100}%)` } : {}}
      >
        {slides.map((slide, i) => (
          <Slide key={i} {...slide} currentSlide={currentSlide} index={i} />
        ))}
      </div>
      <div className="feature-ads__buttons">
        <Button variant="text" onClick={goToPrevious}>
          <p>{`<`}</p>
        </Button>
        <Button variant="text" onClick={goToNext}>
          <p>{`>`}</p>
        </Button>
      </div>
    </div>
  );
};

export default FeatureAds;

const Slide = ({
  img,
  title,
  p1,
  p2,
  currentSlide,
  index,
  button,
}: {
  img: string;
  title: string;
  p1: string;
  p2?: string;
  currentSlide: number;
  index: number;
  button: JSX.Element;
}) => (
  <div className="feature-ads__ad" id="slide">
    <div className="feature-ads__ad_left">
      <div>
        <img
          src={img}
          alt="ad-placement"
          className={currentSlide === index ? 'current-slide-img' : ''}
        />
        {/* {currentSlide === index && <div className="feature-ads__ad_left_slide" />} */}
        <div className="feature-ads__ad_left_slide" />
      </div>
    </div>
    <div
      className={`feature-ads__ad_right ${
        window.innerWidth > 992 && currentSlide === index ? 'current-slide-text' : ''
      }`}
    >
      <h2>{title}</h2>
      <p>
        {p1}
        {p2 && (
          <>
            <br />
            <br />
            {p2}
          </>
        )}
      </p>
      {button}
    </div>
  </div>
);

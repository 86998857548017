import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { Buffer } from 'buffer';
import { LiveChatWidget } from '@livechat/widget-react';
import { useProdEffect } from './global/hooks/useProdEffect';
import { HelmetProvider } from 'react-helmet-async';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Pages } from './global/routes';
import LandingPageLayout from './components/landing-page-layout/landing-page-layout';

import {
  HomePage,
  AboutPage,
  PrivacyPolicyPage,
  DocsPage,
  StatsPage,
  AdvertisersPage,
  PublishersPage,
  BookDemoPage,
  GameDemoPage,
} from './pages';

import Sellers from './pages/selers/selers';

window.Buffer = window.Buffer || Buffer;

const GA_ID: string = process.env.REACT_APP_GOOGLE_ANALYTICS_ID as string;
const GTM_ID: string = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID as string;
const LIVECHAT_LICENSE: string = process.env.REACT_APP_LIVECHAT_LICENSE as string;

function App() {
  const location = useLocation();

  useProdEffect(() => {
    ReactGA.initialize(GA_ID);
    TagManager.initialize({ gtmId: GTM_ID });
  }, []);

  useProdEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location.key]);

  return (
    <HelmetProvider>
      <Routes>
        <Route path="/" element={<LandingPageLayout />}>
          <Route path={Pages.HOME} element={<HomePage />} />
          <Route path={Pages.PUBLISHERS} element={<PublishersPage />} />
          <Route path={Pages.ADVERTISERS} element={<AdvertisersPage />} />
          <Route path={Pages.DOCS_FAQ} element={<DocsPage />} />
          <Route path={Pages.ABOUT} element={<AboutPage />} />
          <Route path={Pages.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
          <Route path={Pages.BOOK_DEMO} element={<BookDemoPage />} />
          <Route path={Pages.GAME_DEMO} element={<GameDemoPage />} />
        </Route>
        <Route path={Pages.STATS} element={<StatsPage />} />
        <Route path="/sellers.json" element={<Sellers />} />
        <Route path="*" element={<Navigate to={Pages.HOME} />} />
      </Routes>
      <LiveChatWidget license={LIVECHAT_LICENSE} visibility="minimized" />
    </HelmetProvider>
  );
}

export default App;

import { Button, ExternalLinkButton } from '../../../components/button/button';

import './partners-card.scss';

const PartnersCard = ({
  title,
  data,
  extended,
  setExtended,
}: {
  title: string;
  data: { logo: string; link: string }[];
  extended: boolean;
  setExtended: any;
}) => {
  return (
    <div className={`partners-card ${extended ? 'partners-card--full' : ''}`}>
      <h3>{title}</h3>
      <div className={`partners-card__logos ${extended ? 'partners-card__logos--full' : ''}`}>
        {data.map((partner) => (
          <ExternalLinkButton key={partner.link} href={partner.link} variant="text">
            <img src={partner.logo} alt="partner link" />
          </ExternalLinkButton>
        ))}
      </div>
      <Button variant="line" onClick={() => setExtended(!extended)}>
        {extended ? 'Collapse' : 'Extend'}
      </Button>
    </div>
  );
};

export default PartnersCard;

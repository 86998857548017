import { submitNewsletterWebhook } from '../../webhooks/newsletter.webhook';
import SubscriptionInput from '../subscription-input/subscription-input';
import './newsletter.scss';

const Newsletter = () => {
  return (
    <div className="newsletter">
      <h4>Subscribe to Our Newsletter</h4>
      <div>
        <p>Your email</p>
        <SubscriptionInput text="Subscribe" submitFormHandler={submitNewsletterWebhook} />
      </div>
    </div>
  );
};

export default Newsletter;

import Icon from '../icon/icon';

import './spinner.scss';

const Spinner = () => {
  return (
    <div className="spinner">
      <Icon name="Loading" />
    </div>
  );
};

export default Spinner;

import { NavLink } from 'react-router-dom';
import { Pages } from '../../global/routes';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import reneRLogo from '../../assets/images/logo/rene-r.svg';
import Icon from '../icon/icon';

import './hamburger-menu.scss';

interface IProps {
  isMenuOpen: boolean;
  setIsMenuOpen: Function;
  children: JSX.Element;
}

const HamburgerMenu: React.FC<IProps> = ({ isMenuOpen, setIsMenuOpen, children }) => {
  const closeMenu = useCallback(() => setIsMenuOpen(false), [setIsMenuOpen]);
  const location = useLocation();

  useEffect(() => {
    closeMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  return (
    <aside className={`hamburger-menu ${isMenuOpen ? 'hamburger-menu--open' : ''}`}>
      <div className="hamburger-menu__backdrop" onClick={closeMenu} />
      <div className="hamburger-menu__menu">
        <div className="hamburger-menu__top">
          <NavLink to={Pages.HOME}>
            <img src={reneRLogo} alt="ReneVerse Logo" />
          </NavLink>
          <button onClick={closeMenu}>
            <Icon name="Close" label="Close" />
          </button>
        </div>
        {children}
      </div>
    </aside>
  );
};

export default HamburgerMenu;

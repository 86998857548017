export enum Pages {
  HOME = '/',
  ABOUT = '/about',
  ADS = '/borderless-ads',
  AIRDROP = '/airdrop',
  NFT_DROP = '/nft-drop',
  PRIVACY_POLICY = '/privacy-policy',
  GAMING = '/gaming',
  DEVELOPMENT = '/development',
  ADVERTISERS = '/advertisers',
  PUBLISHERS = '/publishers',
  GAMER = '/gamer',
  DEVELOPER = '/developer',
  GAME_STUDIO = '/game-studio',
  GAME_STUDIO_FORM = '/game-studio-form',
  GAME_STUDIO_CALENDAR = '/game-studio-calendar',
  SUCCESS = '/success',
  PRODUCT = '/product',
  RENE = '/rene',
  DOCS_FAQ = '/docs-faq',
  STATS = 'stats',
  BOOK_DEMO = '/book-demo',
  GAME_DEMO = '/game-demo',
}

export enum Section {
  sdk = 'sdk',
  portal = 'portal',
  reneId = 'reneId',
  templates = 'templates',
  gameConnect = 'game-connect',
  ads = 'borderless-ads',
  roadmap = 'roadmap',
}

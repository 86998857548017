import { useCalendlyEventListener, InlineWidget } from 'react-calendly';

import './book-demo-page.scss';

const BookDemoPage = () => {
  useCalendlyEventListener({
    onEventScheduled: () => sessionStorage.removeItem('cldl_form_data'),
  });

  useCalendlyEventListener({
    onEventScheduled: () => sessionStorage.removeItem('cldl_form_data'),
  });

  const calendlyEmail = 'https://calendly.com/tommy-reneverse/30min';

  const pageSettings = { hideGdprBanner: true };

  return (
    <div className="book-demo-page">
      <div className="book-demo-page__title">
        Book a <span className="blue-text">Call</span>
      </div>
      <div className="book-demo-page__calendar">
        <InlineWidget url={calendlyEmail} pageSettings={pageSettings} />
      </div>
    </div>
  );
};

export default BookDemoPage;

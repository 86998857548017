import { FC } from 'react';
import { Outlet } from 'react-router';
import Footer from '../footer/footer';
import Header from '../header/header';

import './landing-page-layout.scss';

const LandingPageLayout: FC = () => {
  return (
    <div className="layout">
      <Header />
      <div className="content">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default LandingPageLayout;

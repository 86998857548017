import './input.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name?: string;
  value: string | number | undefined;
  className?: string;
  handleInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: string;
  errorMessage?: string;
  disabled?: boolean;
  readOnly?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  props?: React.InputHTMLAttributes<HTMLInputElement>;
  optional?: boolean;
}

const Input: React.FC<Props> = ({
  value,
  name = '',
  label = null,
  type = 'text',
  className = '',
  placeholder,
  errorMessage,
  handleInput,
  disabled,
  readOnly,
  inputRef,
  optional = false,
  ...props
}) => {
  return (
    <div className={`input_container ${className}`}>
      {label ? (
        <label>
          {label}
          {optional && <span> (optional)</span>}
        </label>
      ) : null}
      <input
        ref={inputRef}
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={handleInput}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
        {...props}
      />
      {errorMessage && <p>{errorMessage}</p>}
    </div>
  );
};

export default Input;

import { useState } from 'react';
import { isObjEmpty } from '../utils';

export const useValidation = (validations: any) => {
  const [errors, setErrors] = useState<{ [key: string]: any }>();

  const isFormInvalid = (form: any) => {
    const validationKeys = Object.keys(validations);
    const mappedErrors: any = {};
    validationKeys.forEach((validationKey: string) => {
      if (validations[validationKey] && validations[validationKey](form, validationKey)) {
        mappedErrors[validationKey] = validations[validationKey](form, validationKey);
      }
    });
    return checkErrors(mappedErrors);
  };

  const isFieldInvalid = (key: string, form: any) => {
    const mappedErrors: any = {};
    if (validations[key] && validations[key](form, key)) {
      mappedErrors[key] = validations[key](form, key);
    }
    return checkErrors(mappedErrors);
  };

  const checkErrors = (mappedErrors: any) => {
    if (!isObjEmpty(mappedErrors)) {
      setErrors(mappedErrors);
      return true;
    } else if (errors) {
      setErrors(undefined);
    }
    return false;
  };

  return { errors, isFieldInvalid, isFormInvalid };
};

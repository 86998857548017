import axios from 'axios';
import { useState, useEffect } from 'react';

export interface TeamDepartment {
  title: string;
  members: {
    name: string;
    role: string;
    url: string;
    description: string;
  }[];
}

export function useTeamMembers() {
  const [teamDepartments, setTeamDepartments] = useState<TeamDepartment[]>([]);
  useEffect(() => {
    axios.get<TeamDepartment[]>('https://cdn.reneverse.io/about-us/team.json').then((res) => {
      setTeamDepartments(res.data);
    });
  }, []);

  return { teamDepartments };
}

import { AxiosResponse } from 'axios';
import { FC, useState } from 'react';
import { useValidation } from '../../global/hooks/useValidation';
import { validations } from './validations';
import { Button } from '../button/button';
import Icon from '../icon/icon';
import Input from '../input/input';

import './subscription-input.scss';

const Success: FC = () => {
  return (
    <div className="subscription-success">
      <Icon name="CheckCircle" label="Success" />
      Thanks for subscribing!
    </div>
  );
};

interface IProps {
  text: string;
  submitFormHandler: (data: any) => Promise<AxiosResponse<any, any>>;
}

const SubscriptionInput: React.FC<IProps> = ({ text, submitFormHandler }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState<{ email: string }>({
    email: '',
  });
  const { errors, isFormInvalid } = useValidation(validations);

  const handleFormSubmit = () => {
    if (isFormInvalid(form)) return false;

    setIsLoading(true);
    submitFormHandler(form)
      .then(() => {
        setIsSuccess(true);
      })
      .finally(() => {
        setIsLoading(false);

        setTimeout(() => {
          setIsSuccess(false);
          setForm({ email: '' });
        }, 10000);
      });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = e;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="subscription">
      <Input
        handleInput={handleChange}
        value={form.email}
        placeholder="Enter info"
        name="email"
        errorMessage={errors?.email}
      />
      {isSuccess ? <Success /> : null}
      <Button
        className="subscription__button"
        type="submit"
        variant="secondary"
        size="sm"
        disabled={isLoading}
        onClick={handleFormSubmit}
      >
        {text}
      </Button>
    </div>
  );
};

export default SubscriptionInput;

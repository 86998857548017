import { FC } from 'react';
import { Icons, IconsKey } from './icon-types';

interface IconProps {
  name: IconsKey;
  label?: string;
  mirrored?: boolean;
}

const Icon: FC<IconProps> = ({ name, label }) => {
  return <i className={`icon icon-${Icons[name]}`} aria-label={label || name} />;
};

export default Icon;

import { useState } from 'react';
import { Button } from '../button/button';

import './carousel.scss';

const Carousel = ({
  slides,
  SlideComponent,
}: {
  slides: any;
  SlideComponent: React.ElementType<any>;
}) => {
  const [inputChecked, setInputChecked] = useState(1);

  const inputHandler = (e: any) => {
    setInputChecked(parseInt(e.target.name));
  };

  const buttonHandler = (direction: 'back' | 'forward') => {
    if (direction === 'back') {
      if (inputChecked === 1) {
        setInputChecked(3);
      } else {
        setInputChecked(inputChecked - 1);
      }
    }
    if (direction === 'forward') {
      if (inputChecked === 3) {
        setInputChecked(1);
      } else {
        setInputChecked(inputChecked + 1);
      }
    }
  };

  return (
    <div className="carousel">
      <input
        type="radio"
        name="1"
        id="item-1"
        checked={`item-${inputChecked}` === 'item-1'}
        onChange={inputHandler}
      />
      <input
        type="radio"
        name="2"
        id="item-2"
        checked={`item-${inputChecked}` === 'item-2'}
        onChange={inputHandler}
      />
      <input
        type="radio"
        name="3"
        id="item-3"
        checked={`item-${inputChecked}` === 'item-3'}
        onChange={inputHandler}
      />
      <div className="carousel__cards">
        {slides.map(
          (slide: { title: string; img: string; description: JSX.Element }, i: number) => (
            <label
              key={slide.title}
              className="carousel__cards_label"
              htmlFor={`item-${i + 1}`}
              id={`card-${i + 1}`}
            >
              <SlideComponent {...slide} />
            </label>
          ),
        )}
      </div>
      <div className="carousel__buttons">
        <Button variant="text" onClick={() => buttonHandler('back')}>
          <p>{`<`}</p>
        </Button>
        <Button variant="text" onClick={() => buttonHandler('forward')}>
          <p>{`>`}</p>
        </Button>
      </div>
    </div>
  );
};

export default Carousel;

import { useState } from 'react';
import { DEVELOPER_DOCS_URL } from '../../global/constants';
import { ExternalLinkButton } from '../../components/button/button';
import { Button } from '../../components/button/button';
import { data } from './data';
import Icon from '../../components/icon/icon';
import HeadMeta from '../../components/head-meta';
// import Carousel from '../../components/carousel/carousel';

import './docs-page.scss';

const cards = [
  // {
  //   title: 'Product Whitepaper',
  //   text: 'Read up on the ReneVerse Product Whitepaper to gain deep insight into ReneVerse’s various features.',
  //   link: WHITEPAPER_URL,
  // },
  {
    title: 'Developer Documentation',
    text: 'Dig into our Developer Docs to get step-by-step instructions on integrating ReneVerse tech into your game.',
    link: DEVELOPER_DOCS_URL,
  },
  // {
  //   title: 'Token Whitepaper',
  //   text: 'Dive into the ReneVerse Token Whitepaper to learn about $RENE token use cases, tokenomics, and more.',
  //   link: TOKENOMICS_URL,
  // },
];

const Card = ({ title, text, link }: { title: string; text: string; link: string }) => (
  <div className="docs-page__docs_card">
    <h2>{title}</h2>
    <p>{text}</p>
    <ExternalLinkButton href={link} variant="line">
      View document
    </ExternalLinkButton>
  </div>
);

const Question = ({
  index,
  title,
  description,
}: {
  index: string | number;
  title: string;
  description: JSX.Element;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="docs-page__faq_question">
      <h3>{index}</h3>
      <Button variant="text" onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <Icon name="Close" /> : <Icon name="Plus" />}
      </Button>
      <div className="docs-page__faq_box_content_question_content">
        <h4>{title}</h4>
        {isOpen && description}
      </div>
    </div>
  );
};

const FaqBox = ({
  title,
  questions,
}: {
  title: string;
  questions: { title: string; description: JSX.Element }[];
}) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <div className="docs-page__faq_box">
      <div className="docs-page__faq_box_heading">
        <Button variant="text" onClick={() => setIsOpen(!isOpen)}>
          <h2>{title}</h2>
          {isOpen ? <Icon name="ChevronUp" /> : <Icon name="ChevronDown" />}
        </Button>
      </div>
      {isOpen && (
        <div className="docs-page__faq_box_content">
          {questions?.map((question, i) => (
            <Question
              key={i}
              index={i < 10 ? `0${i + 1}` : i + 1}
              title={question.title}
              description={question.description}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const DocsPage = () => {
  return (
    <div className="docs-page">
      <HeadMeta title="FAQ page" page="docs" />
      <section className="docs-page__docs">
        <h1>Docs</h1>
        <div>
          {cards.map((card) => (
            <Card key={card.title} title={card.title} text={card.text} link={card.link} />
          ))}
        </div>
      </section>
      <section className="docs-page__faq">
        <h1>FAQ</h1>
        <FaqBox title="Game | Publisher FAQ" questions={data.games} />
        <FaqBox title="Brand | Advertiser FAQ" questions={data.brands} />
        <FaqBox title="Other FAQ" questions={data.other} />
      </section>
    </div>
  );
};

export default DocsPage;

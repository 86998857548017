import './banner-slider.scss';

const BannerSlider = ({ slides }: { slides: { logo: string; link: string }[] | undefined }) => {
  return (
    <div className="banner-slider">
      <div className="banner-slider__slide-track">
        {slides &&
          slides.map((slide, i) => (
            <a
              key={i}
              className="banner-slider__slide-track_slide"
              href={slide.link}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={slide.logo} height="100" width="250" alt="" />
            </a>
          ))}
        {slides &&
          slides.map((slide, i) => (
            <a
              key={i}
              className="banner-slider__slide-track_slide"
              href={slide.link}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={slide.logo} height="100" width="250" alt="" />
            </a>
          ))}
      </div>
    </div>
  );
};

export default BannerSlider;
